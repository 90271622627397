import { Component, OnInit } from '@angular/core';
import { EntriesService } from './../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { CardService } from '../services/card.service';
import { trigger, style, animate, transition, query, stagger } from '@angular/animations';
import { errorNotification } from '../services/notifications.service';
import { AuthService } from './../services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StripePayment } from './../classes/stripe-payment';
import { firstValueFrom } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-online-checkout',
  templateUrl: './online-checkout.component.html',
  styleUrls: ['./online-checkout.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0ms', style({ opacity: 0 }))
      ])
    ]
    ),
    trigger(
      'OutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ]
    ),
    trigger('itemAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('150ms', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ]
})
export class OnlineCheckoutComponent implements OnInit {
  public email: any;
  public idConcurso: any
  public entries: any[];
  public bill: any;
  public user: any;
  public HTMLElements: any;
  public version: any
  public currentShow: any;
  public paymentMethods: any;
  public stripe: StripePayment | null;

  constructor(
    private _entriesService: EntriesService,
    private _cardService: CardService,
    private _personsService: PersonsService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.email = this._activatedRoute.snapshot.paramMap.get('email');
    this.idConcurso = ((this._activatedRoute.snapshot.paramMap.get('idConcurso') || '').match(/[0-9]/g) || []).join('');;
    this.entries = [];
    this.bill = {
      entry: {},
      stripe_customer_id: ''
    };

    this.version = environment.version;
    this.paymentMethods = [];
    this.currentShow = {};
    this.user = {};
    this.HTMLElements = {
      accordion1Text: 'Please select the entry you want to pay:',
      accordion2Text: '',
      accordion3Text: '',
      newCardFormVisible: false,
      orderSucceded: false,
    };
    this.stripe = null;
  }

  async ngOnInit() {
    const show: any = await this._entriesService.getEntryInfo(this.idConcurso);
    if (show.error) {
      errorNotification('Error', show.message);
      return;
    }
    this.currentShow = show;
    sessionStorage.setItem('club_id', show.id_club);
    const { user } = await this._personsService.getDocumentByEmail(this.email).then(user => ({ user })).catch(reason => ({ user: { error: reason.error } }));
    const club = sessionStorage.getItem('club_id')||'';
    //Si no existe el documento de usuario
    if(user.error && user.error.code == 'not_found'){
      //Crea el documento de usuario
      this.user = await this._authService.createUserDoc(this.email, this.email).catch(reason => ({ error: reason.error }));
      if(this.user.error) return errorNotification('Error', this.user.error.message);

      //crea un stripe customer
      const { customer, reason } = await this._cardService.createStripeCustomer(this.user.uid, this.idConcurso, club, this.email).then((r: any) => r).catch(reason => ({ reason }));
      if(reason) return errorNotification('Error', reason.error.message||(reason.error||reason));

      this.bill.stripe_customer_id = customer.id;
      this.getSaldo();
    } else if(user.error){
      return errorNotification(user.error.code, user.error.message);
    }

    this.user = user;
    //Obtiene el stripe customer
    const { customer, reason } = await this._cardService.getStripeCustomer(this.user.uid, club).then((customer: any) => ({ customer, reason: null })).catch(reason => ({ reason, customer: null }));
    //Si no existe el stripe customer
    if(reason && reason.error && reason.error.code == 'not_found'){
       //crea un stripe customer
       const { customer, reason } = await this._cardService.createStripeCustomer(this.user.uid, this.idConcurso, club, this.email).then((r: any) => r).catch(reason => ({ reason }));
       if(reason) return errorNotification('Error', reason.error.message||(reason.error||reason));

       this.bill.stripe_customer_id = customer.id
       this.getSaldo();
    } else if(reason){
      return errorNotification(reason.error.code||'Error', reason.error.message||(reason.error||reason));
    }
    this.bill.stripe_customer_id = customer.id
    this.getSaldo();

  }

  public getSaldo() {
    firstValueFrom(this._entriesService.getSaldo(this.idConcurso, this.email)).then(
      (response: any) => {
        this.entries = response.entries.filter((e: any) => e.saldo > 0)
      },
      error => {
        console.log(error)
      }
    )
  }

  public selectEntry(index: any) {
    this.bill.entry = this.entries[index];
    this.HTMLElements.accordion1Text = `Entry selected: ${this.bill.entry.horse} (${this.bill.entry.entry})`
    this.getPaymentMethod()
    this.HTMLElements.accordion2Text = `Please, select a payment method`
    setTimeout(() => {
      this.emulateClick('accordion-2')
    }, 500);
  }

  public selectPaymentMethod(event: any) {
    if (event.target.value == 'new-card') {
      this.HTMLElements.newCardFormVisible = true;
      this.HTMLElements.RegisteredCardFormVisible = false;
      this.HTMLElements.accordion2Text = `Please fill out the following form`
      this.createPaymentIntent(this.bill.entry);
    } else {
      this.HTMLElements.RegisteredCardFormVisible = true;
      this.HTMLElements.newCardFormVisible = false;
      this.bill.paymentMethod = this.paymentMethods[event.target.value];
      this.HTMLElements.accordion2Text = `Click on Pay to finish transaction`
    }
  }

  async processPayment(event: any) {
    const cardHolderName = $('#stripeCardHolderName').val() || '';
    const metadata = {
      recived_by: 'Online',
      showName: this.currentShow.show_name || '',
      show: this.idConcurso,
      entry: ''
    };
    const { paymentIntent } = await this.stripe!.confirmPayment({ email: this.email, name: cardHolderName, metadata }).then(this.handleResponse, this.handleError).catch(this.handleError);
    if (!paymentIntent) return;
    this.orderSucceded();
  }

  public orderSucceded() {
    this.HTMLElements.orderSucceded = true;
    this.HTMLElements.accordion3Text = `Payment Succeded`;
    setTimeout(() => {
      this.emulateClick('accordion-3');
    }, 500);
  }

  public agregarComision(amount: number): number {
    const comision = 1 + (this.currentShow.public_key ? this.currentShow.comision_stripe : .0325);
    return amount * comision;
  }

  emulateClick(elementName: string) {
    const anchorElement = document.getElementById(elementName);
    if (anchorElement) {
      const event = new MouseEvent('click', {
        bubbles: true, cancelable: true, view: window
      });
      anchorElement.dispatchEvent(event);
    }
  }

  async createPaymentIntent(entry: any, card_id?: string) {
    const card_selected: string = card_id || '';
    const amount: number = this.agregarComision(this.bill.entry.saldo);
    const description: string = `Show: ${this.currentShow.show_name}, Entry: ${entry.horse} (${entry.entry})`;
    var d = new Date,
      dformat = [d.getFullYear(), (d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1), (d.getDate() < 10 ? `0${d.getDate()}` : d.getDate())].join('-');
    const metadata = {
      total_amount: entry.saldo,
      amout_with_fee: amount,
      entry: description,
      transaction: true,
      idConcurso: this.idConcurso,
      idUsuario: `online-entries-web`,
      entry_number: this.bill.entry.entry,
      metodo: 'Tc',
      montoCobrado: this.bill.entry.saldo,
      fecha: dformat,
    };
    if (!card_selected) {
      const paymentElement = await this.stripe!.createPayment({ amount, customer_id: this.bill.stripe_customer_id, description, email: this.email, metadata }).then(this.handleResponse, this.handleError).catch(this.handleError);
      if (!paymentElement) return;
      paymentElement.mount("#payment-element");
    } else {
      const { paymentIntent } = await this.stripe!.createPaymentIntent({ amount, customer_id: this.bill.stripe_customer_id, description, email: this.email, metadata, paymentMethod: card_selected || '' }).then(this.handleResponse, this.handleError).catch(this.handleError);
      if (!paymentIntent) return;
      this.orderSucceded();
    }
  }

  async getPaymentMethod() {
    const customer_id = this.bill.stripe_customer_id;
    const show_id = this.idConcurso;
    const response = await firstValueFrom(this._cardService.getStripePaymentMethods(customer_id, show_id));
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.paymentMethods = response.paymentMethods.data.map((p: any) => ({
      last4: p.card.last4,
      funding: p.card.funding,
      exp_month: p.card.exp_month,
      exp_year: p.card.exp_year,
      brand: p.card.brand,
      card_id: p.id,
      cardholder: p.billing_details.name
    }));
  }

  handleError(reason: any) {
    const message = reason.error || (reason.message || reason);
    errorNotification('Error', `Error creating payment intent. ${message}`);
    return;
  }

  handleResponse(response: any) {
    if (response.error) {
      this.handleError(response.message);
      return;
    }
    return response;
  }

  openEntryInvoice(entry: string) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/entry-invoice/${this.idConcurso}/${entry}`])
    );
    window.open(url, '_blank');
  }
}
