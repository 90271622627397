import { Component, OnInit } from '@angular/core';
import { PersonsService } from './../services/persons.service';
import { firstValueFrom } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  public dias: any[];
  public filtro: string;
  public idConcurso: string;
  public email: string;
  public idEntrenador: string;
  public loading: boolean;

  constructor(private personsService: PersonsService) {
    this.dias = [];
    this.filtro = '';
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.idEntrenador = localStorage.getItem('idEntrenador')||'';
    this.idConcurso = sessionStorage.getItem('showID')||'';
    this.loading = false;
  }

  async ngOnInit() {
    //if (!this.idEntrenador) await this.getTrainerByEmail();
    this.getTrainerList();
  }

  private async getTrainerByEmail() {
    const response = await this.personsService.getByEmail(this.email, this.idConcurso);
    if (response.error) {
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    }
    const entrenador = response.entrenador;
    this.idEntrenador = entrenador.idEntrenador;
  }

  private async getTrainerList() {
    console.log('getTrainerList', this.idEntrenador);
    const response = await firstValueFrom(this.personsService.getTrainerList(this.idConcurso, this.email));
    console.log('getTrainerList', response);
    if (response.error) {
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    }
    //const adds_drops = response.adds_drops;
    this.dias = Object.entries(response.pruebas).map((r: [string, any]) => {
      const [fulldate, pistas] = r;
      const [dayName, date] = fulldate.split(/\s/);
      const [year, month, day] = (date||'').split(/-/);
      return {
        //date: fulldate,
        date: `${dayName||''} ${day||''}`,
        rings: Object.entries(pistas).map((p: [string, any]) => {
          const [name, classes] = p;
          return {
            name,
            classes: Object.entries(classes).map((c: [string, any]) => {
              const [num, cl] = c;
              return {
                number: num,
                entries: cl
              };
            })
          };
        })
      };
    });
  }

  filtrarAlumnosPorDia(dia: string = '') {
    this.filtro = dia || '';
  }
}
