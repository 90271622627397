<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row">

          <div class="col-12 col-xl-8 offset-xl-2">
            <div class="card d-block mt-4">
              <div class="card-body">
                <button type="button" class="btn btn-dark" (click)="goBack()" routerLinkActive="router-link-active">
                  <i class="mdi mdi-arrow-left-thick me-1"></i>
                  <span> Back to Dashboard</span>
                </button>
              </div>
            </div>

          </div>
          <div class="col-12 col-xl-8 offset-xl-2 mt-4">
            <div class="btn-group col-12">
              <button *ngFor='let day of days' class="fc-dayGridMonth-button btn btn-primary" type="button"
                (click)="selectDay(day)" [ngClass]="day == selectedDay ? 'active' : 'inactive'">{{day}}</button>
            </div>
          </div>
          <div class="col-12 col-xl-8 offset-xl-2 mt-4">
            <div class="app-search col-12" style="display:unset">
              <form>
                <div class="input-group p-2 searchDiv">
                  <input type="text" #classSearchInput disabled class="form-control" placeholder="Please select a day">

                  <span class="mdi mdi-magnify search-icon"></span>
                  <!--button class="input-group-text btn-primary" disabled>Search</button-->
                </div>
              </form>
            </div>
          </div>
          <div class="col-12 col-xl-8 offset-xl-2">
            <div *ngIf="availableClasses.length == 0 && hasSearched && !isSearching"
              class="text-muted text-small mt-1 ml-2">
              No results
            </div>
            <div *ngIf="isSearching">
              Searching...
            </div>
            <div *ngIf="availableClasses.length > 0 && !isSearching" class="text-muted text-small mt-1 ml-2">
              {{resultsNo}} matching classes
            </div>
            <div *ngIf="availableClasses.length > 0" class="row mt-4">
              <div class="col">
                <div data-simplebar="init">
                  <div class="simplebar-wrapper" style="margin: 0px;">
                    <div class="simplebar-content" style="padding: 0px;">
                      <div *ngFor='let availableClass of availableClasses; let index = index' class="availableClass">
                        <a href="javascript:void(0);" class="text-body">
                          <div class="d-flex align-items-start mt-1 p-2" [ngClass]="{'bg-light': index % 2 == 0}">
                            <div class="w-100 overflow-hidden">
                              <h5 class="mt-0 mb-0 font-14">
                                <span class="float-end text-muted font-12">{{availableClass.number}}</span>
                                {{availableClass.name}}
                              </h5>
                              <div class="mt-1 text-muted font-14">Division: {{availableClass.division}}</div>
                              <p class="mt-1 mb-0 text-muted font-14">
                                <span class="w-75">{{availableClass.table}}</span>
                              </p>
                              <p class="mt-1 mb-0 text-muted font-14">
                                <span class="mdi mdi-calendar"></span>
                                <span class="w-75">{{availableClass.date | date:'EEEE, MMMM d'}}</span>
                              </p>
                            </div>
                            <div>
                              <div class="d-grid">
                                <button type="submit" class="btn btn-success chat-send"
                                  (click)="addClass(availableClass.idPrueba)">ADD</button>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> <!-- end slimscroll-->
              </div> <!-- End col -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
