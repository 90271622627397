<!-- Loader -->
<div id="loader" *ngIf="loading || pagoEnProceso" [@fade]>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- Fin Loader -->
<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12" *ngIf="!showId">
            <button class="button btn btn-primary" routerLink="/select-show">
              Please Select a Show
            </button>
            <br>
            <br>
          </div>
          <div class="col-12" *ngIf="showId">
            <div class="card d-block mt-4">
              <div class="card-body">

                <h3>{{ nombreConcurso }}</h3>
                <h4>Your Entries</h4>
                <h5>{{email}}</h5>
                <p class="mt-2">
                  Includes entries related to this email address (rider, trainer, owner).
                  <br>
                  Please contact the show office if you need access to additional entries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div *ngFor="let entry of entries" class="col-6 col-lg-3">
          <a href="javascript:void(0);" (click)="goToEntry(entry.idBinomio)" class="cardLink">
            <div class="card d-block entryCard">
              <div class="card-body">
                <h4 class="mt-0">
                  <span class="text-title">{{entry.backNumber}} - {{entry.caballo}}</span>
                </h4>
                <h6>Rider</h6>
                <div class="rider">
                  {{entry.jinete | titlecase}}
                </div>

                <div *ngFor="let rider of entry.riders" class="rider">
                  {{rider}}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>