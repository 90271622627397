<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-12" *ngIf="!showID">
          <button class="button btn btn-primary" routerLink="/select-show">
            Please Select a Show
          </button>
          <br>
          <br>
        </div>
        <div class="col-md-6 col-sm-12" *ngIf="showID">
          
          <h2>{{showName}}</h2>
          <ul class="list-group">
            <h4>Entries created Online</h4>
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let draft of drafts">

              <span>
                <i class="mdi mdi-horse-human me-1"></i>
                {{ draft.horseName }}
                <span *ngIf="draft.horseUSEF">({{ draft.horseUSEF }})</span>
                <b>{{ draft.discipline == 'all' ? '' : ' - ' + draft.discipline }}</b>

              </span>
              <!-- pending approval -->
              <ng-container *ngIf="draft.status=='draft'">
                <span>
                  <span class="badge rounded-pill text-bg-secondary">
                    {{draft.status}}
                  </span>
                  <a href="javascript:;" class="ms-2" [routerLink]="['/edit-entry-reining', draft.uid]"
                    routerLinkActive="router-link-active" *ngIf="reining">
                    <span class="btn btn-warning btn-sm">Edit</span>
                  </a>
                  <a href="javascript:;" class="ms-2" [routerLink]="[checkoutRoute]"
                    routerLinkActive="router-link-active">
                    <span class="btn btn-success btn-sm">Pay</span>
                  </a>
                </span>
              </ng-container>

              <!-- <span>
                <span class="badge rounded-pill" [ngClass]="{
                  'text-bg-secondary': draft.status == 'draft',
                  'bg-dark text-light': draft.status == 'Pending Show Approval',
                  'bg-success': draft.status == 'accepted',
                  'bg-danger': draft.status == 'rejected'
                }">{{ draft.status == 'Pending Show Approval' ? 'Pending Back Number' : draft.status}} </span>
                <a href="javascript:;" class="ms-2" [routerLink]="['/edit-entry-reining', draft.uid]"
                  routerLinkActive="router-link-active" *ngIf="draft.status == 'draft' && reining">
                  <span class="badge bg-info rounded-pill">Edit</span>
                </a>
                <a href="javascript:;" class="ms-2" [routerLink]="[checkoutRoute]" routerLinkActive="router-link-active"
                  *ngIf="draft.status == 'draft'">
                  <span class="badge bg-success rounded-pill">Go to payment</span>
                </a>
              </span> -->
            </li>

            <h4>Entries in Office System <small> (includes accepted online entries)</small></h4>
            <ng-container *ngFor="let entry of oldEntries">
              <li *ngIf="entry.id_concurso == showID && (entry.estatus == '1' || entry.estatus == '2')"
                class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  <i class="mdi mdi-horse-human me-1"></i>
                  <strong>
                    {{entry.entry}}
                  </strong>
                  
                  {{ entry.caballo.horseName }}
                  <span *ngIf="entry.caballo.horseUSEF">({{ entry.caballo.horseUSEF }})</span>
                 
                </span>
                <!-- pending approval -->
                <span class="badge rounded-pill" [ngClass]="{
                  'text-bg-secondary': entry.estatus == '0',
                  'bg-dark text-light': entry.estatus == '1',
                  'bg-success': entry.estatus == '2',
                  'bg-danger': entry.estatus == 'rejected'
                }">{{ entry.estatus == 1 ? 'Pending Back Number' : entry.estatus == 2 ? 'Accepted' : '' }}</span>
              </li>
            </ng-container>

          </ul>
        </div>

        <div class="col-xl-4 col-lg-6">
          <div *ngIf="!reining" class="card cta-box bg-primary text-white">
            <div class="card-body">
              <div class="d-flex align-items-start align-items-center">
                <div class="w-100 overflow-hidden">
                  <h2 class="mt-0 text-reset"><i class="mdi mdi-bullhorn-outline"></i>&nbsp;</h2>
                  <h3 class="m-0 fw-normal cta-box-title text-reset"></h3>
                  <p>Following payment, add/scratches can be made once the show office accepts your entry.</p>
                  <p>Draft entries can be edited.</p>
                  <p>If you need any assistance please contact us at <a
                      href="mailto:hello@equestrian.digital">hello@equestrian.digital</a>

                  </p>

                </div>
                <img class="ms-3" src="assets/images/svg/email-campaign.svg" width="120"
                  alt="Generic placeholder image">
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <div *ngIf="reining" class="card cta-box bg-primary text-white">
            <div class="card-body">
              <div class="d-flex align-items-start align-items-center">
                <div class="w-100 overflow-hidden">
                  <h2 class="mt-0 text-center"><i class="mdi mdi-alert-outline"></i></h2>
                  <p>Following payment, add/scratches can be made once the show office accepts your entry.</p>
                  <p>Draft entries can be edited.</p>
                  <p>If you need any assistance please contact us at <a
                      href="mailto:support@100xshows.com">support@100xshows.com</a>

                </div>
                <!-- <img class="ms-3" src="assets/images/svg/email-campaign.svg" width="120"
                  alt="Generic placeholder image"> -->
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card-->

          <!-- Todo-->


        </div>
      </div>
    </div>
  </div>
</div>