import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from  './../services/auth.service';
import { Router } from '@angular/router';

declare var $ : any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  public signUpForm : FormGroup;
  constructor(
    private _authService : AuthService,
    private _router      : Router
  ){
    this.signUpForm = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    })
  }

  public validateForm(){
    if(!this.signUpForm.valid){
      // #TODO: Add notification of invalid form
      console.log('Incomplete form');
      return;
    }
    this.validateCredentials()
  }

  public validateCredentials(){
    this._authService.signUpRequest(this.signUpForm.value.username,this.signUpForm.value.password, this.signUpForm.value.fullname).then(
      () => {
        console.log('Redirect to home');
        this._router.navigate(['/login']);
      }
    ).catch( error => console.log(error.message))
  }
}
