<div class="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 800">
      <g fill-opacity="0.22">
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.1);" cx="400" cy="400" r="600"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.2);" cx="400" cy="400" r="500"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.3);" cx="400" cy="400" r="300"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.4);" cx="400" cy="400" r="200"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.5);" cx="400" cy="400" r="100"></circle>
      </g>
  </svg>
</div>
<div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-xxl-4 col-lg-5">
              <div class="card">
                  <!-- Logo-->
                  <div class="card-header py-4 text-center bg-primary">
                      <a href="index.html">
                          <span><img src="assets/images/logo.png" alt="logo" height="82"></span>
                      </a>
                  </div>

                  <div class="card-body p-4">
                      
                      <div class="text-center w-75 m-auto">
                          <h4 class="text-dark-50 text-center mt-0 fw-bold">Free Sign Up</h4>
                          <p class="text-muted mb-4">Don't have an account? Create your account, it takes less than a minute </p>
                      </div>

                      <form [formGroup]="signUpForm" (submit)="validateForm()" class="needs-validation">

                          <div class="mb-3">
                              <label for="fullname" class="form-label">Full Name</label>
                              <input class="form-control" type="text" id="fullname" placeholder="Enter your name" required formControlName="fullname">
                          </div>

                          <div class="mb-3">
                              <label for="emailaddress" class="form-label">Email address</label>
                              <input class="form-control" type="email" id="emailaddress" required placeholder="Enter your email" formControlName="username">
                          </div>

                          <div class="mb-3">
                              <label for="password" class="form-label">Password</label>
                              <div class="input-group input-group-merge">
                                  <input type="password" id="password" class="form-control" placeholder="Enter your password" required formControlName="password">
                              </div>
                          </div>

                          <div class="mb-3 text-center">
                              <button class="btn btn-primary" type="submit"> Sign Up </button>
                          </div>

                      </form>
                  </div> <!-- end card-body -->
              </div>
              <!-- end card -->

              <div class="row mt-3">
                  <div class="col-12 text-center">
                      <p class="text-muted">Already have account? <a href="pages-login.html" class="text-muted ms-1"><b>Log In</b></a></p>
                  </div> <!-- end col-->
              </div>
              <!-- end row -->

          </div> <!-- end col -->
      </div>
      <!-- end row -->
  </div>
  <!-- end container -->
</div>