declare var require: any;
export const environment = {
  production: true,
  version: require('../../package.json').version,
  developers: ['alex@ecuestre.digital', 'mauricio@vende.io', 'alan@vende.io'],
  api: {
    ed: {
      host: 'https://api.equestrian.digital/',
      key: '7cc263a1-a490-4337-8302-151490495e56'
    },
    nest: {
      host: 'https://nest.equestrian.digital/v1/'
    },
    nrha: {
      host: 'https://data.nrha.com/api/private/rs/',
      key: 'Y[>KQf)Rs}JU_G>>}DL^WB$OF6X{O$d1$(=<(jbLl{Fq0iIZiOENPkkuZrIiNxAvYJ^{@mfOaX6?7YoA]mw[)2Oy1M3T]+$Ja%!8G7%FbyW'
    },
    reining: {
      host: 'https://reining.ecuestrian.digital'
    }
  }
};
