import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from  "@angular/common/http";
import { Observable } from 'rxjs';
import { Firestore, addDoc } from '@angular/fire/firestore';
import { collection, getDocs, doc, query, where, onSnapshot , setDoc, deleteDoc} from "firebase/firestore";
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HorsesService {
  constructor(
    private http: HttpClient,
    private _firestore : Firestore
  ) {
  }

  public async setHorseSuggestion(userId:any, data:any): Promise<any>{
    return new Promise(async (resolve, reject) => {
      const suggestionReference = collection(this._firestore, `users/${userId}/horseSuggestions`);
      try {
        const docRef = await addDoc(suggestionReference, data);
        return resolve({ documentId: docRef.id });
      } catch (error){
        return reject({ reason: error });
      }
    });
  }

  public getHorseByUSEF(usef:string, idConcurso:string){
    const params = JSON.stringify({ usef, idConcurso });
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });
	  return this.http.post(`${environment.api.ed.host}caballos/usef/`, params, { headers: headers });
  }

  public getHorseByNrha(nrha:string){
    const params = { id: nrha };
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });
	  return this.http.get(`${environment.api.ed.host}nrha/horse/`, { headers: headers, params });
  }

  public getHorseByFEI(fei:string){
    const params = JSON.stringify({ fei });
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });

	  return this.http.post(`${environment.api.ed.host}caballos/fei/`, params, { headers: headers });
  }

  public getHorseByNRHA(nrha:number){
    console.log('Querying NRHA for horse: ', nrha);

    const params = JSON.stringify({licenseNumber: 550805});

    console.log('Querying NRHA with params: ', params);

    const headers: HttpHeaders = new HttpHeaders({
      Access: environment.api.nrha.key,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",

    });

    return this.http.post(`${environment.api.nrha.host}horses`, params, { headers: headers });

  }

  async getHorsesSuggestions(user:any):Promise<any>{
    console.log('Getting horse suggestions for user: ', user);
    let documents:any = [];
    const usersReference = collection(this._firestore, `users/${user}/horseSuggestions`);
    const q = query(usersReference);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let document:any = doc.data()
      document.uid = doc.id;
      documents.push(document)
    });
    return documents;
  }

  async deleteHorseSuggestion(user:any, documentId:any):Promise<any>{
    const document = doc(this._firestore, `users/${user}/horseSuggestions/${documentId}`);
    deleteDoc(document);
  }
}
