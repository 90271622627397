<div class="wrapper">
    <app-top-menu></app-top-menu>
    <app-left-menu></app-left-menu>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row mt-3">
                <div class="col-md-12 col-sm-12" style="margin: 0 auto;">

                    <ng-container *ngIf="!sr && !tc">
                        Loading...
                    </ng-container>
                    <!-- Split Rock -->
                    <ng-container *ngIf="sr">
                        <!-- June -->
                        <ng-container *ngIf="showID == 479 || showID == 480">
                            <iframe id="GoogleForm"
                                style=" width: 100%; border: none; padding: 0px; margin: 0 auto; height: 80vh;"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSezd2S34g4E4BfJcZqyZmUT4b-Mk2MrnROe3R2p5j5RU5aiGg/viewform"
                                frameborder="0"></iframe>
                        </ng-container>
                        <!-- Portland -->
                        <ng-container *ngIf="showID == 481 || showID == 482">
                            <iframe id="GoogleForm"
                                style=" width: 100%; border: none; padding: 0px; margin: 0 auto; height: 80vh;"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSe4LQ-r44FCb2InUbbii0nDZWzfZvgnhsqFYyYc1CXnlqjcvg/viewform?usp=sf_link"
                                frameborder="0"></iframe>
                        </ng-container>
                        <!-- Fall -->
                        <ng-container *ngIf="showID == 483 || showID == 484 || showID == 485">
                            <iframe id="GoogleForm"
                                style=" width: 100%; border: none; padding: 0px; margin: 0 auto; height: 80vh;"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSft527GE46-cCT0-7A4tqud3EbKtrrrELaX89hcEI274ImTFQ/viewform?usp=sf_link"
                                frameborder="0"></iframe>
                        </ng-container>
                        <!-- Aiken -->
                        <ng-container *ngIf="showID == 486">
                            <iframe id="GoogleForm"
                                style=" width: 100%; border: none; padding: 0px; margin: 0 auto; height: 80vh;"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSc_nkmSBTu7tUUfhQjdIzT9GwRVAYEP7iYWfRaoF3bVUj_nOg/viewform?usp=sf_link"
                                frameborder="0"></iframe>
                        </ng-container>
                        <ng-container *ngIf="showID == 487 || showID == 488">
                            <p>
                                Stall reservations not available for this show.
                            </p>
                            <p>
                                Please complete your entries to reserve stalls.
                            </p>
                        </ng-container>
                        <!-- Ft Worth -->
                        <ng-container *ngIf="showID == 489">
                            <iframe id="GoogleForm"
                                style=" width: 100%; border: none; padding: 0px; margin: 0 auto; height: 80vh;"
                                src="https://docs.google.com/forms/d/e/1FAIpQLSe_jpJtuGHTjqktBhzQpAPdn9zrDLyvXt_9HcIQWRxcMJ2qTA/viewform?usp=sf_link"
                                frameborder="0"></iframe>
                        </ng-container>

                    </ng-container>
                    <!-- Traverse City -->
                    <ng-container *ngIf="tc">
                        <ng-container *ngIf="[491, 493, 494].includes(showID)">
                            <h5>Please fill this form and email to: <strong>susie&#64;mmg.management</strong> </h5>
                            <a class="btn btn-primary" target="_blank"
                                href="/assets/pdfs/Spring-Stall-Reservations-fillable.pdf">
                                <i class="mdi mdi-download"></i>Spring Stall Reservation Form
                            </a>
                        </ng-container>
                        <ng-container *ngIf="[495].includes(showID)">
                            <h5>Please fill this form and email to: <strong>susie&#64;mmg.management</strong> </h5>
                            <a class="btn btn-primary" target="_blank"
                                href="/assets/pdfs/JHNC-Entry-forms.pdf">
                                <i class="mdi mdi-download"></i>ADEQUAN / USEF JUNIOR HUNTER NATIONAL CHAMPIONSHIP EAST
                                Stall Reservation Form
                            </a>
                        </ng-container>
                        <ng-container *ngIf="[497, 498, 499, 501, 504, 506].includes(showID)">

                            <h5>Please fill this form and email to: <strong>susie&#64;mmg.management</strong> </h5>
                            <a class="btn btn-primary" target="_blank"
                                href="/assets/pdfs/GLEF-Stall-Reservation-Form_fillable.pdf">
                                <i class="mdi mdi-download"></i> GLEF Stall Reservation Form</a>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>
