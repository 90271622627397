<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-md-6 col-12 mt-1">
          <div class="row">
            <div class="lists col-md-6 col-sm-12">
              <h5 class="mt-0 task-header">ENTRIES PENDING TO PAY</h5>
              <div id="task-list-one" class="task-list-items">
                <div class="card" *ngFor="let d of drafts">
                  <div class="card-body p-2">

                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center px-2 py-1">
                        <h4 class="m-0">{{ d.horseName }} ({{ d.horseNrha }})</h4>
                        <span class="badge"
                          [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{
                          d.discipline }}</span>
                      </li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account text-muted me-1"></i> Owner: {{
                        d.ownername }} {{ d.ownerLastname }}</li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account-hard-hat text-muted me-1"></i>
                        Trainer: {{ d.trainername }} {{ d.trainerLastname }}</li>
                      <ng-container *ngFor="let rider of d?.riders">
                        <li class="list-group-item px-2 py-1"><i class="mdi mdi-horse-human text-muted me-1"></i> Rider:
                          {{ rider.firstname }} {{ rider.lastname }}</li>
                      </ng-container>
                      <li class="list-group-item px-2 py-1" style="text-align: right;">
                        <b> {{d.balance | currency}}</b>
                      </li>
                    </ul>


                    <div class="row">
                      <div class="col-2">
                        <i *ngIf="actions.moveObject" (click)="deleteDraft(d.uid)"
                          class="float-left ri-delete-bin-2-line font-25 pointer text-danger"></i>
                      </div>
                      <div class="col-8"></div>

                      <div class="col-2"> <i *ngIf="actions.moveObject" (click)="moveObject(d,drafts,paying)"
                          class="float-right ri-arrow-right-circle-fill font-25 pointer text-success"></i>
                      </div>
                    </div>

                    <div>
                      <!-- Details <i class="ri-zoom-in-fill font-25 pointer text-success"></i>  -->
                      <h4><strong>Classes</strong></h4>
                      <div *ngFor="let prueba of d.pruebas" style="font-size: 0.8em;">
                        <b>{{ prueba.numero }}</b>
                        {{ prueba.nombre}}
                        <span *ngIf="prueba.costo > 0">({{ prueba.costo | currency}})</span>


                         <!-- {{prueba.charges | json}} -->
                          <!-- <div *ngFor="let charge of prueba.charges">
                            {{ charge.nombre }}
                            {{ charge.monto_unitario | currency}}
                          </div> -->


                        <!-- {{prueba | json}}-->
                      </div>
                      <h4>Fees</h4>
                      <ul>
                        <li *ngFor="let fee of d.conceptos" style="font-size: 0.8em;">
                          {{ fee.name }}: {{ fee.amount | currency }}
                        </li>
                      </ul>

                      <h4>SubTotal: {{ d.balance | currency}}</h4>
                      <small>Note: Does not include 3% processing fee.</small>


                    </div>

                  </div> <!-- end card-body -->
                </div>
              </div> <!-- end company-list-1-->
            </div>

            <div class="lists col-md-6 col-sm-12">
              <h5 class="mt-0 task-header text-uppercase">PAYING NOW</h5>
              <div id="task-list-two" class="task-list-items">
                <div class="card" *ngFor="let d of paying">
                  <div class="card-body p-2">
                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center px-2 py-1">
                        <h4 class="m-0">{{ d.horseName }} ({{ d.horseNrha }})</h4>
                        <span class="badge"
                          [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{
                          d.discipline }}</span>
                      </li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account text-muted me-1"></i> Owner: {{
                        d.ownername }} {{ d.ownerLastname }}</li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account-hard-hat text-muted me-1"></i>
                        Trainer: {{ d.trainername }} {{ d.trainerLastname }}</li>
                      <ng-container *ngFor="let rider of d?.riders">
                        <li class="list-group-item px-2 py-1"><i class="mdi mdi-horse-human text-muted me-1"></i> Rider:
                          {{ rider.firstname }} {{ rider.lastname }}</li>
                      </ng-container>
                      <li class="list-group-item px-2 py-1" style="text-align: right;">
                        <b> {{d.balance | currency}}</b>
                      </li>
                    </ul>
                    <i *ngIf="actions.moveObject" (click)="moveObject(d,paying,drafts)"
                      class="float-right ri-arrow-left-circle-fill font-25 pointer text-success"></i>
                  </div> <!-- end card-body -->
                </div>
              </div> <!-- end company-list-1-->
              <div class="card cta-box bg-primary text-white">
                <div class="card-body">
                  <div class="d-flex align-items-start align-items-center">
                    <div class="w-100 overflow-hidden">
                      <h2 class="mt-0 text-center"><i class="mdi mdi-alert-outline"></i></h2>
                      <!-- <p>Following payment, add/scratches can be made or entry draft can be deleted and remade </p> -->
                      <p>If you need any assistance please contact us at <a href="mailto:support@100xshows.com">support@100xshows.com</a>
                    </div>
                    <!-- <img class="ms-3" src="assets/images/svg/email-campaign.svg" width="120"
                      alt="Generic placeholder image"> -->
                  </div>
                </div>
                <!-- end card-body -->
              </div>
              <div *ngIf="actions.canGoToPayment" class="d-grid mt-3">
                <button *ngIf="actions.moveObject" (click)="selectPaymentMethod()" class="btn btn-success btn-sm">Select payment method</button>
                <button *ngIf="!actions.moveObject" (click)="returnToSelectEntries()" class="btn btn-danger btn-sm">Return</button>
              </div>
            </div>

          </div> <!-- end .board-->
        </div> <!-- end col -->
        <div class="col-md-6 col-12" *ngIf="actions.StripeFormVisible">
          <app-stripe-payment-form [customers]="[stripeCustomer]" [showId]="showID" [amount]="cartTotals.total" [metadata]="metadata" [description]="description" [defaultCardHolder]="defaultCardHolder" (onError)="handleError($event)" (onConfirmPayment)="handleSubmit($event)"></app-stripe-payment-form>
        </div>
        <div class="col-12">
          <!-- Text Info -->
          <div class="card border-info border mt-3">
            <div class="card-body">
              <!--h5 class="card-title text-info">Special title treatment</h5-->
              <p class="card-text">Click on the right arrow button to select entries you want to pay for now (you can pay for more than one at a time)'.</p>
              <!--a href="javascript: void(0);" class="btn btn-info btn-sm">Button</a-->
            </div> <!-- end card-body-->
          </div> <!-- end card -->
        </div>
    </div>
  </div>
</div>
