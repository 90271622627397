<!-- Loader -->
<div id="loader" *ngIf="loading" [@fade]>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- Fin Loader -->
<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="modal fade" id="modal-folio" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
          style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Success</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div class="modal-body">
                Your reservation was sent successfully. Reservation #: <b>{{ folio }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
              </div>
            </div><!-- /.modal-content -->
          </div><!-- /.modal-dialog -->
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="col-12" *ngIf="idConcurso == '431';">
            <!-- else formulario -->
            <div class="card">
              <div class="card-body p-2">
                <p class="m-0"> if you have not already reserved stalls please call Kaitlin at 337-852-5620</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4" *ngIf="reservations.length > 0">
            <div class="card">
              <div class="card-body">
                <h4 class="header-title">Your reservations</h4>
                <div class="row justify-content-center">
                  <div class="col-12 mt-2" style="font-size: .75rem;" *ngFor="let reservation of reservations">
                    <ul class="list-group">
                      <li class="list-group-item justify-content-between d-flex"><span>Created:</span><span>{{ reservation.created | date }}</span></li>
                      <li class="list-group-item"><b>Reserved items:</b></li>
                      <li class="list-group-item justify-content-between d-flex" *ngFor="let cargo of reservation.cargos">
                        <span>{{ cargo.nombre }} ({{ cargo.qty }})</span>
                        <span>{{ cargo.monto | currency }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ng-template #formulario-->
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <!-- Cargos -->
                <h4 class="header-title mb-2">Reservation items</h4>
                <div class="row ">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="row">
                      <div class=" col-sm-12 col-12" *ngFor="let concepto of conceptos">
                        <!-- col-xl-6 col-lg-6 col-md-6 -->
                        <form class="form-inline">
                          <div class="form-group w-50">
                            <input type="text" readonly="" class="form-control-plaintext" [value]="concepto.nombre"
                              disabled>
                          </div>
                          <div class="form-group mr-2 mb-1">
                            <input (change)="agergarCargo(concepto.id, concepto.monto, concepto.nombre, $event)"
                              type="number" min="0" max="100" style="width: 4rem; padding: 0; text-align: center;"
                              class="form-control" placeholder="Qty">
                          </div>
                          <div class="form-group">
                            <label style="color: #212529; font-weight: normal;">{{ concepto.monto | currency }}</label>
                          </div>
                        </form>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                        <h5 class="mb-0">TOTAL: {{ totalCargos() | currency }}</h5>
                      </div>
                      <div class="col-12 mt-3 text-center">
                        <button *ngIf="(cards.length > 0 && !public_key) || public_key" class="btn btn-success"
                          type="button" (click)="save()">Reserve</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4" *ngIf="cards.length == 0 && !public_key">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="header-title mb-2">Reservation items</h4>
                    <form class="needs-validation" (ngSubmit)="addCard()" [formGroup]="form" novalidate
                      autocomplete="off">
                      <div class="form-group">
                        <!--label class="text-left">

                          </label-->
                        <input type="text" formControlName="customerName" placeholder="Name" class="form-control"
                          required>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend" tabindex="-1">
                            <span class="input-group-text"
                              style="background: transparent; border-right: 0;padding: 0 .9rem;" tabindex="-1"><i
                                class="mdi mdi-credit-card" tabindex="-1"></i></span>
                          </div>
                          <input id="cardNumber" formControlName="ccAccountNum" type="text" placeholder="Card number"
                            class="form-control" style="border-left: 0; padding-left: 0; border-right: 0;"
                            data-toggle="input-mask" data-mask-format="9999 9999 9999 9990" minlength="18" required
                            tabindex="0" autocomplete="asdfsdFsdF">
                          <div class="input-group-append">
                            <input type="text" placeholder="MM/YYYY" formControlName="ccExp" class="form-control"
                              style="border-left: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;"
                              data-toggle="input-mask" data-mask-format="00/0000" minlength="7" required
                              autocomplete="asdfsdFsdF">
                          </div>
                          <div class="invalid-feedback d-block">
                            <!-- {{ errorTarjeta }} -->
                          </div>
                        </div>
                      </div>
                      <div class="form-group text-center">
                        <button class="btn btn-success" type="submit">Save card</button>
                      </div>
                    </form>
                    <p>A valid credit card is required to hold stalls. The card will not be run at this time.</p>
                    <p>Please type in all of your card information, do not autofill. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/ng-template-->
        </div>
      </div>
    </div>
  </div>
</div>
