import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { EntriesService } from '../services/entries.service';
import { AuthService } from '../services/auth.service';
import { debounceTime, map, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-add-classes',
  templateUrl: './add-classes.component.html',
  styleUrls: ['./add-classes.component.scss']
})
export class AddClassesComponent implements OnInit {
  @ViewChild('classSearchInput', {}) classSearchInput: any;

  public idBinomio: string = '';
  public idConcurso: string;
  public availableClasses: any = [];
  private _daysMap: Map<string, string>;//any = ['Tue', 'Wed', 'Thu', 'Fri','Sat', 'Sun']; // Fixed for now

  public resultsNo: any = '';
  public selectedDay: any = '';
  email: string = '';
  searchText: string = '';
  isSearching: boolean;
  hasSearched: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entriesService: EntriesService,
    private _authService: AuthService
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.idBinomio = routeParams.get('idBinomio')||'';
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.idConcurso = sessionStorage.getItem('showID') || '';
    this.isSearching = false;
    this.hasSearched = false;
    this._daysMap = new Map<string, string>();
  }

  public get days(): string[] {
    return [...this._daysMap.values()];
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    this.getAvailableDays();
  }

  ngAfterViewInit() {
    fromEvent(this.classSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => res.length > 1),
      debounceTime(275), // Tiempo despues de terminar de teclear
      //distinctUntilChanged() // Asegura que haya cambiado el texto
    ).subscribe((text: string) => {
      this.searchText = text.trim();
      this.getAvailableClasses(this.searchText);
    }
    );
  }

  private getAvailableDays() {
    this.entriesService.getAvailableDays(this.idBinomio).subscribe(response => {
      response.days.filter((day: string) => day).forEach((day: string) => {
        this._daysMap.set(day, day);
      });
    }
    );
  }

  public selectDay(day: string) {
    if(!day){
      this.selectedDay = '';
      return;
    };
    this.classSearchInput.nativeElement.disabled = false;
    this.classSearchInput.nativeElement.placeholder = 'Search for division or class...';
    this.selectedDay = day;
    //this.classSearchInput.nativeElement.value = '';
    this.availableClasses = [];
    this.getAvailableClasses(this.searchText);

  }

  public getAvailableClasses(searchText: any) {
    if (searchText.length > 1) {
      this.isSearching = true;
      this.entriesService.getAvailableClasses(this.idBinomio, searchText, this.selectedDay).subscribe(
        response => {
          this.resultsNo = response.results;
          this.availableClasses = response.pruebas;
          this.availableClasses.forEach((e: { status: string; }) => {
            // Definir estatus de prueba
            e.status = 'can-enter';
          });
          this.isSearching = false;
          this.hasSearched = true;
        }
      )
    }
  }

  public addClass(idPrueba: string) {
    console.log('Adding Class');
    this.entriesService.addDropClass(this.idBinomio, this.email, idPrueba).subscribe(response => {
      this.entriesService.updateEntries(this.idConcurso, idPrueba);
      this.getAvailableClasses(this.searchText);
    }
    )
  }

  public goToEntry() {
    this.router.navigate(['/entry', this.idBinomio]);
  }

  public goBack(){
    this.router.navigate(['/entry', this.idBinomio]);
  }
}

