<title>History</title>
<!-- Loader -->
<div id="loader">
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- Fin Loader -->
<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row justify-content-center mt-4">
          <div class="col-12" *ngIf="idConcurso == '431'">
            <div class="card">
              <div class="card-body p-2">
                <p class="m-0"> if you have not already reserved stalls please call Kaitlin at 337-852-5620</p>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="card">
              <div class="card-body">
                <table class="table table-sm mb-0 table-hover">
                  <thead>
                    <tr>
                      <th>Show</th>
                      <th>Horse</th>
                      <th>Rider</th>
                      <th>Trainer</th>
                      <th>Payee</th>
                      <th>Status</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let solicitud of solicitudes">
                      <td>{{ solicitud.concurso }}</td>
                      <td>{{ solicitud.caballo.horseName }}</td>
                      <td>{{ solicitud.jinete.riderName }} {{ solicitud.jinete.riderLastName }}</td>
                      <td>{{ solicitud.nombreEntrenador }}</td>
                      <td>{{ solicitud.responsable.payeeName }} {{ solicitud.responsable.payeeLastName }}</td>
                      <td>{{ solicitud.nombreEstatus }}</td>
                      <td>{{ solicitud.created | date: 'dd MMMM yyyy - HH:mm' | titlecase }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
