import { Component, OnInit, OnDestroy } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { errorNotification } from '../services/notifications.service';
import { Router } from '@angular/router';
import { EmailsService } from '../services/emails.service';
import { environment } from './../../environments/environment';
import { Club } from '../enums/clubs';

declare let window: any;

@Component({
  selector: 'app-select-show',
  templateUrl: './select-show.component.html',
  styleUrls: ['./select-show.component.scss']
})
export class SelectShowComponent implements OnInit, OnDestroy {
  public shows: any[];
  public email: string;
  public username: string;
  public uid: string;
  public production: boolean;
  public isAdmin: boolean;

  constructor(
    private _entriesService: EntriesService,
    private _personsService: PersonsService,
    private _authService: AuthService,
    private _cardService: CardService,
    private _router: Router,
    private _emailsService: EmailsService
  ) {
    this.shows = [];
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.username = localStorage.getItem('username')||(sessionStorage.getItem('username')||'');
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.production = environment.production;
    this.isAdmin = localStorage.getItem('isAdmin') == '1';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if (!this.uid) {
      this._personsService.getDocumentByEmail(this.email)
        .then((doc: any) => {
          this.uid = doc.uid;
          localStorage.setItem('user_document_id', this.uid);
          console.log('User document id: ', this.uid);
        })
        .catch((error: any) => {
          console.log('Error:', error);
          this._emailsService.sendEmail(
            'Error getting user document id',
            `User: ${this.email}  got an error getting user document id: ${this.uid} \n
            ${error.message}. \n
            This is in the select-show component.`
          );
        });
    }

    if (!this.email || !this.uid) {
      this._router.navigate(['/login']);
    }
    this.getShows();
  }

  ngAfterViewInit() {
    window.__lo_site_id = 297466;

    const userData = {
      'email': this.email,
      'name': this.email,
    };

    window._loq = window._loq || [];
    window._loq.push(['custom', userData]);
  }

  ngOnDestroy(): void {

  }

  public getShows() {
    this._entriesService.getConcursos().subscribe(
      (response: any) => {
        this.shows = response.concursos.filter((show: any) => {
          if(location.hostname != environment.api.reining.host) return true;
          return Club.reining == show.id_club;
        }).map((show: any) => ({
          ...show,
          inicio: show.inicio.replace(/-/g, '/'),
          fin: show.fin.replace(/-/g, '/'),
          reining: Club.reining == show.id_club,
          tc: Club.traverseCity == show.id_club,
        }));
        console.log(this.shows)
      },
      error => {
        console.log(error)
      }
    )
  }

  async selectShow(showID: string, showname: string, club_id: string){
    sessionStorage.setItem('club_id', club_id);
    sessionStorage.setItem('showID', showID);
    sessionStorage.setItem('showname', showname);
    sessionStorage.setItem('reining', club_id == Club.reining ? '1' : '0');
    sessionStorage.setItem('tc', club_id == Club.traverseCity ? '1' : '0');
    sessionStorage.setItem('sr', club_id == Club.splitRock ? '1' : '0');

    const createEntryRoute = club_id == Club.reining ? '/add-entry' : '/create-entry';
    //4. Redirecting...;
    this._router.navigate([createEntryRoute]);
    return;
  }

  public is_included(element: any, ...array: any): boolean {
    return array.includes(element);
  }

  public sendErrorEmail(title: string, error: any, customer: string) {
    this._emailsService.sendEmail(
      title + ' for ' + customer,
      error
    );
  }
}
