import { Component, OnInit } from '@angular/core';
import { EntriesService } from './../services/entries.service';
import { AuthService } from './../services/auth.service';
declare var $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  public solicitudes: any[];
  public email: string;
  public idConcurso: string

  constructor(private entriesService: EntriesService, private _authService: AuthService) {
    this.solicitudes = [];
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.idConcurso = sessionStorage.getItem('showID')||'';
  }

  async ngOnInit(){
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if (!this.idConcurso) return;
    this.getInscripciones();
  }

  public async getInscripciones() {
    const response: any = await this.entriesService.getSolitudesPorMail(this.email, this.idConcurso);
    if(response.error) {
      $('#loader').hide();
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    }
    $('#loader').hide();
    this.solicitudes = response.solicitudes.map((solicitud: any) => {
      if (solicitud.estatus == 0) {
        solicitud.nombreEstatus = 'Pending (Unpaid)';
      } else if (solicitud.estatus == 1) {
        solicitud.nombreEstatus = 'Pending (Paid)';
      } else if (solicitud.estatus == 2) {
        solicitud.nombreEstatus = 'Accepted (Unsigned)';
      } else if (solicitud.estatus == 3) {
        solicitud.nombreEstatus = 'Accepted (Signed)';
      } else {
        solicitud.nombreEstatus = '';
      }

      return solicitud;
    });
    return response;
  }
}
