import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FeedOrdersService } from './../services/feed-orders.service';
import { EntriesService } from './../services/entries.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EmailsService } from './../services/emails.service';

declare let $: any;

@Component({
  selector: 'app-feed-orders',
  templateUrl: './feed-orders.component.html',
  styleUrls: ['./feed-orders.component.scss']
})
export class FeedOrdersComponent implements OnInit, AfterViewInit{
  public email: string;
  public idConcurso: string | number;
  public errorMessage: string;
  public entrenador: any | null;
  public orders: FeedOrder[];
  public dataTableOrders: any;
  public orderForm: FormGroup;
  public chargeForm: FormGroup;
  public orderCharges: any[];
  public concepts: any[];
  public entries: any[];
  public saving: boolean;
  public organizer: any;
  public selectedOrder: string | number;
  public tc: boolean;

  constructor(private feedOrdersService: FeedOrdersService, private formBuilder: FormBuilder, private entriesService: EntriesService, private emailsService: EmailsService) {
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.organizer = sessionStorage.getItem('club_id')||'';
    this.idConcurso = sessionStorage.getItem('showID')||'';
    this.errorMessage = '';
    this.entrenador = null;
    this.orders = [];
    this.dataTableOrders = null;
    this.orderForm = this.formBuilder.group({
      stall: ['', Validators.required],
      trainer: [''],
      entry: ['']
    });
    this.chargeForm = this.formBuilder.group({
      concept: ['', Validators.required],
      notes: [''],
      quantity: ['', [Validators.required,Validators.min(1)]],
      total: [{ value: '', disabled: true }, [Validators.required, Validators.min(1)]],
    });
    this.orderCharges = [];
    this.concepts = [];
    this.entries = [];
    this.saving = false;
    this.selectedOrder = '';
    this.tc = (sessionStorage.getItem('tc')||'0') == '1';
  }

  async ngAfterViewInit() {
    this.dataTableOrders = $("#datatable-orders").DataTable({
      columnDefs: [{ className: "table-action", targets: 6}, { orderable: false, targets: [5, 6]}],
      lengthChange:!1,
      language:{
        paginate:{
          previous:"<i class='mdi mdi-chevron-left'>",
          next:"<i class='mdi mdi-chevron-right'>"
        }
      },
      rowId: 0,
      createdRow: (row: Node, data: any[], dataIndex: number, cells: Node[]) => {
        const [id] = data;
        const [lastCell] = cells.reverse();
        lastCell.addEventListener('click', () => {
          console.log('click', id);
          this.showEditOrderModal(id);
        });
      },
      drawCallback: () => {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded')
      }
    });
    //Consulta entrenador con email y idConcurso
    this.entrenador = await this.getTrainer();
    if(!this.entrenador) return;
    this.entries = await this.getEntries();
    //Consulta feed orders con idConcurso y idEntrenador
    this.orders = await this.getFeedOrders();
    this.dataTableOrders.rows.add(this.orders.map(o => [o.id||'', o.stall||'',o.trainer_name||'', o.entry||'', o.total||'', o.delivered||'', !o.delivered ? `<a href="javascript: void(0);" class="action-icon"> <i class="mdi mdi-pencil"></i></a>` : ''])).draw(false);
  }

  async ngOnInit(){
    this.concepts = await this.getFeedConcepts();
  }

  public get totalOrder(): number {
    return this.orderCharges.reduce((acc, c) => acc + c.linea, 0);
  }

  public async getTrainer(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      const { entrenador, error } = await this.feedOrdersService.getTrainer(this.email, this.idConcurso);
      if(error){
        this.errorMessage = error;
        console.log('error', error);
        return reject();
      }
      return resolve(entrenador);
    });
  }

  public async getFeedOrders(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      const { orders, error } = await this.feedOrdersService.getFeedOrders(this.entrenador.idEntrenador, this.idConcurso);
      if(error){
        this.errorMessage = error;
        console.log('error', error);
        return reject();
      }
      return resolve(orders);
    });
  }

  public async getFeedConcepts(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      const { conceptos, error } = await this.feedOrdersService.getFeedConcepts(this.idConcurso);
      if(error){
        this.errorMessage = error;
        console.log('error', error);
        return reject();
      }
      return resolve(conceptos);
    });
  }

  public async getEntries(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      const { entries, error } = await this.entriesService.getTrainerEntries(this.entrenador.idEntrenador, this.idConcurso);
      if(error){
        this.errorMessage = error;
        console.log('error', error);
        return reject();
      }
      return resolve(entries);
    });
  }

  public showAddOrderModal(){
    this.selectedOrder = '';
    this.orderCharges = [];
    this.orderForm.reset();
    this.chargeForm.reset();
    $('#addOrderModal').modal('show');
  }

  public showEditOrderModal(id: string | number){
    this.selectedOrder = id;
    const order: any = this.orders.find(o => o.id == id);
    this.orderCharges = order.charges||[];
    this.orderForm.setValue({
      stall: order.stall||'',
      trainer: order.trainer||'',
      entry: order.entry||''
    });
    this.chargeForm.reset();
    $('#addOrderModal').modal('show');
  }

  public updateTotal(){
    const conceptId = this.chargeForm.getRawValue().concept;
    //Si no hay concepto, no se calcula total
    if(!conceptId){
      this.chargeForm.get('total')?.setValue('');
      return;
    }

    const concept = this.concepts.find(c => c.id == conceptId);
    const unitCost = parseFloat(concept?.monto||'0');
    const quantity = this.chargeForm.getRawValue().quantity||0;

    //Si hay concepto pero no hay cantidad, agregar 1 a cantidad y poner total
    if(!quantity){
      console.log('Si hay concepto pero no hay cantidad, agregar 1 a cantidad y poner total');
      this.chargeForm.get('quantity')?.setValue(1);
      this.chargeForm.get('total')?.setValue(unitCost);
      return;
    }

    //Si hay concepto y cantidad, calcular total
    const total = (unitCost*quantity)||0;
    this.chargeForm.get('total')?.setValue(total);
  }

  public addCharge(){
    if(!this.chargeForm.valid) return;
    const concept = this.concepts.find(c => c.id == this.chargeForm.getRawValue().concept);
    if(!concept) return;
    this.orderCharges.push({
      id: concept.id,
      linea: this.chargeForm.getRawValue().total,
      notas: this.chargeForm.getRawValue().notas||'',
      cantidad: this.chargeForm.getRawValue().quantity|| 1,
      producto: concept.nombre||'',
      unitario: parseFloat(concept?.monto||'0')
    });
    this.chargeForm.reset();
  }

  public removeCharge(index: number){
    this.orderCharges.splice(index, 1);
  }

  public async addOrder(){
    //Validar formulario
    if(!this.orderForm.valid) return;
    //Validae que haya entrenador o entry
    if(!this.orderForm.getRawValue().trainer && !this.orderForm.getRawValue().entry){
      $.NotificationApp.send('Error', 'There is no coach or entry selected, please select at least one of them to continue', 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    //Validar que haya cargos
    if(!this.orderCharges.length){
      $.NotificationApp.send('Error', 'There are no items selected, select at least one to continue', 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }

    this.setSaving(true);

    const newOrder = {
      idConcurso: this.idConcurso,
      idEntrenador: this.orderForm.getRawValue().trainer||'',
      entry: this.orderForm.getRawValue().entry||'',
      idUsuario: 371,//Usuario online
      stall: this.orderForm.getRawValue().stall||'',
      total: this.totalOrder,
      charges: this.orderCharges,
      online: true
    };

    //Crear orden en mysql
    const { order, error } = await this.feedOrdersService.createFeedOrder(newOrder);
    if(error){
      this.setSaving(false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    this.orders.push({
      id: order.id,
      show: order.id_concurso,
      trainer: order.id_entrenador,
      trainer_name: order.trainer,
      entry: order.entry,
      stall: order.stall,
      delivered: order.delivered,
      deliveredBy: order.delivered_by,
      total: order.order_total,
      charges: order.data||[],
      online: true
    });
    this.dataTableOrders.row.add([order.id||'', order.stall||'',order.trainer||'', order.entry||'', order.order_total||'', order.delivered||'', `<a href="javascript: void(0);" class="action-icon"> <i class="mdi mdi-pencil"></i></a>`]).draw(false);

    //Crear orden en firestore
    const newOrderFS = {
      data: order.data.map((d: any) => ({ cantidad: `${d.cantidad}`, producto: d.producto })),
      id: order.id,
      id_concurso: order.id_concurso,
      stall: order.stall,
      delivered: false,
      created_at: new Date(order.created_at),
      trainer: order.trainer,
      online: true
    };
    await this.feedOrdersService.createFeedOrderFS(newOrderFS);

    //Send email
    this.emailsService.sendEmail(`New feed order: ${order.id} - ${sessionStorage.getItem('showname')||''}`, `New feed order: <b>${order.id}</b><br>Show: ${sessionStorage.getItem('showname')||''}<br>Trainer: ${order.trainer}`, 'howie630@gmail.com', true);

    this.setSaving(false);
    $('#addOrderModal').modal('hide');
    $.NotificationApp.send('Success', 'Order was created successfully', 'bottom-right', '#5ba035', 'success', 10000);
  }

  public async editOrder(){
    //Validar formulario
    if(!this.orderForm.valid) return;
    //Validae que haya entrenador o entry
    if(!this.orderForm.getRawValue().trainer && !this.orderForm.getRawValue().entry){
      $.NotificationApp.send('Error', 'There is no coach or entry selected, please select at least one of them to continue', 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    //Validar que haya cargos
    if(!this.orderCharges.length){
      $.NotificationApp.send('Error', 'There are no items selected, select at least one to continue', 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }

    this.setSaving(true);

    const newOrder = {
      id: this.selectedOrder,
      idConcurso: this.idConcurso,
      idEntrenador: this.orderForm.getRawValue().trainer||'',
      entry: this.orderForm.getRawValue().entry||'',
      idUsuario: 371,//Usuario online
      stall: this.orderForm.getRawValue().stall||'',
      total: this.totalOrder,
      charges: this.orderCharges,
      online: true
    };

    //Crear orden en mysql
    const { order, error } = await this.feedOrdersService.editFeedOrder(newOrder);
    if(error){
      this.setSaving(false);
      $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error', 10000);
      return;
    }
    //Actualizar order en tabla
    const index = this.orders.findIndex(o => o.id == order.id);
    this.orders[index] = {
      id: order.id,
      show: order.id_concurso,
      trainer: order.id_entrenador,
      trainer_name: order.trainer,
      entry: order.entry,
      stall: order.stall,
      delivered: order.delivered,
      deliveredBy: order.delivered_by,
      total: order.order_total,
      charges: order.data||[],
      online: true
    };
    const row = this.dataTableOrders.row(`#${order.id||''}`);
    row.remove();
    this.dataTableOrders.row.add([order.id||'', order.stall||'',order.trainer||'', order.entry||'', order.order_total||'', order.delivered||'', `<a href="javascript: void(0);" class="action-icon"> <i class="mdi mdi-pencil"></i></a>`]).draw(false);

    //Crear orden en firestore
    const newOrderFS = {
      data: order.data.map((d: any) => ({ cantidad: `${d.cantidad}`, producto: d.producto })),
      id: order.id,
      id_concurso: order.id_concurso,
      stall: order.stall,
      delivered: false,
      created_at: new Date(order.created_at),
      trainer: order.trainer,
      online: true
    };
    await this.feedOrdersService.createFeedOrderFS(newOrderFS);

    this.setSaving(false);
    $('#addOrderModal').modal('hide');
    $.NotificationApp.send('Success', 'Order was edited successfully', 'bottom-right', '#5ba035', 'success', 10000);
  }

  public setSaving(saving: boolean){
    this.saving = saving;
    if(saving){
      this.orderForm.disable();
      this.chargeForm.disable();
    } else{
      this.orderForm.enable();
      this.chargeForm.enable();
    }
  }
}

