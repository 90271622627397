import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { loadStripe, Stripe, StripeElements, StripePaymentElement, PaymentIntent } from '@stripe/stripe-js';
import { StripeService } from './stripe.service';

@Component({
  selector: 'app-stripe-payment-form',
  templateUrl: './stripe-payment-form.component.html',
  styleUrls: ['./stripe-payment-form.component.scss']
})
export class StripePaymentFormComponent implements OnInit, OnChanges, OnDestroy {
  //@Input() public publicKey: string;
  @Input() public customers: StripeCustomer[];
  @Input() public showId: string | number;
  @Input() public amount: string | number;
  @Input() public metadata?: any;
  @Input() public description?: any;
  @Input() public defaultCardHolder?: string;
  @Output() public onError: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onConfirmPayment: EventEmitter<any> = new EventEmitter<any>();
  publicKey: string | null;
  cardFee: number;
  stripe: Stripe | null;
  loading: boolean;
  processingPayment: boolean;
  reining: boolean;
  sr: boolean;
  paymentMethod: string;
  defaultCustomer: string | null;
  elements: StripeElements | null;
  validPaymentElement: boolean;
  cardHolderName: string;

  public get total(): string | number {
    if (this.paymentMethod == 'ach') return this.amount;
    const comision = (1 + this.cardFee);
    return parseFloat(`${this.amount}`) * comision;;
  }

  constructor(private stripeService: StripeService) {
    this.publicKey = null;
    this.stripe = null;
    this.loading = true;
    this.processingPayment = false;
    this.cardFee = 0;
    this.reining = (sessionStorage.getItem('reining')||'0') == '1';
    this.sr = (sessionStorage.getItem('sr')||'0') == '1';
    this.paymentMethod = '';
    this.defaultCustomer = null;
    this.elements = null;
    this.validPaymentElement = false;
    this.cardHolderName = '';
  }

  async ngOnInit() {
    await this.getEntryInfo();
    this.stripe = await loadStripe(this.publicKey!);
    await this.getPaymentMethods();
    const [defaultCustomer] = this.customers;
    if (defaultCustomer) this.defaultCustomer = defaultCustomer.id;
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    //Monto es el unico parametro que puede cambiar
    if (changes['amount'] && !changes['amount'].firstChange) {
      this.destroyPaymentElement();
      if (this.paymentMethod) this.paymentMethodSelected(this.paymentMethod, this.defaultCustomer!);
    }
  }

  ngOnDestroy() {

  }

  async getEntryInfo() {
    const { entryInfo, error } = await this.stripeService.getEntryInfo(this.showId).then((response: any) => ({ entryInfo: response, error: null })).catch(this.handleError);
    if (error) {
      this.loading = false;
      this.onError.emit(error);
      return;
    }
    //Check if club = 377 (SR)
    //this.sr = entryInfo.id_club == '377';
    //Check if club = 563 (100X)
    //this.reining = entryInfo.id_club == '563';
    // Check if the environment is not production else check if the club_id is '377' (SR) else set the public key with entryInfo data or an empty string if it's not available
    this.publicKey = location.hostname == 'localhost' ? 'pk_test_HCDB9zFmxkQut8e9xXpXV0Gm' : (this.sr ? 'pk_live_TWoiGgZLPptcjqdqcyA5CezG' : (entryInfo.public_key || ''));
    this.cardFee = entryInfo.comision_stripe || 0.0325;
    return;
  }

  async getPaymentMethods() {
    const promises = this.customers.map((c, i) => this.stripeService.getStripePaymentMethods(c.id, this.showId).then(r => {
      this.customers[i].paymentMethods = r.paymentMethods.data || [];
      return r;
    }));
    const { response, error } = await Promise.all(promises).then((response: any) => ({ response, error: null })).catch(this.handleError);
    if (error) {
      this.loading = false;
      this.onError.emit(error);
      return;
    }
    console.log('getPaymentMethods', this.customers);
    return;
  }

  async paymentMethodSelected(paymentMethod: string, customerId?: string) {
    //Assign the default customer if it's not provided (only for new_card and ach)
    if (!customerId) {
      const [defaultCustomer] = this.customers;
      if (defaultCustomer) this.defaultCustomer = defaultCustomer.id;
    } else {
      this.defaultCustomer = customerId;
    }
    //Assign the payment method to the selected one
    this.paymentMethod = paymentMethod;
    //If the payment method is new_card or ach, show the stripe form (create payment intent)
    if (['ach', 'new_card'].includes(paymentMethod)) {
      //Show stripe form / create payment intent
      let opts: CreatePaymentIntentOptions = {
        amount: this.total,
        idConcurso: this.showId,
        customer: this.defaultCustomer,
        metadata: this.metadata,
        description: this.description
      }
      this.cardHolderName = this.defaultCardHolder || '';
      if (paymentMethod == 'ach') opts.paymentMethodTypes = ['us_bank_account'];
      const { clientSecret, error } = await this.stripeService.createPaymentIntent(opts).then(r => ({ clientSecret: r.clientSecret, error: r.message || null })).catch(this.handleError);
      if (error) {
        this.onError.emit(error);
        return;
      }
      //TODO: Validar si ya existe un payment element, si existe, destruirlo para despues crear uno nuevo
      this.validPaymentElement = false;
      this.destroyPaymentElement();
      this.createPaymentElement(clientSecret);
    } else {
      //Validar si ya existe un payment element, si existe, destruirlo
      this.destroyPaymentElement();
    }
  }

  async destroyPaymentElement() {
    if (!this.elements) return;
    const paymentElement = this.elements.getElement('payment');
    if (!paymentElement) return;

    paymentElement.destroy();
    this.elements = null;

    return;
  }

  async createPaymentElement(clientSecret: string): Promise<any> {
    if (!clientSecret) return;
    if(!this.stripe){
      this.stripe = await loadStripe(this.publicKey!);
    }
    if(!this.stripe) return;
    this.elements = this.stripe!.elements({ clientSecret, locale: 'en' });
    const paymentElement: StripePaymentElement = this.elements.create('payment');
    paymentElement.mount('#payment-element');
    paymentElement.on('change', (event) => {
      console.log('change', event);
      if (event.complete && this.cardHolderName) {
        // enable payment button
        this.validPaymentElement = true;
      } else {
        this.validPaymentElement = false;
      }
    });
  }

  async submitPayment(e: Event) {
    e.preventDefault();
    this.setLoading(true);
    if(!this.stripe){
      this.stripe = await loadStripe(this.publicKey!);
    }
    if(!this.stripe) return;
    //return_url: Solo para metodos de pago que requieran redireccionamiento
    const { paymentIntent, error } = await this.stripe!.confirmPayment({
      elements: this.elements!,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: this.cardHolderName||''
          }
        },
        return_url: 'http://localhost:4242/public/checkout.html'
      },
      redirect: 'if_required' }).then(r => r.error ? this.handleError(r.error) : r).catch(this.handleError);
    if (error) {
      this.setLoading(false);
      this.onError.emit(error);
      this.showMessage(error);
      return;
    }
    this.setLoading(false);
    this.onConfirmPayment.emit({ ...paymentIntent!, cardFee: this.paymentMethod == 'ach' ? 0 : this.cardFee, method: this.paymentMethod != 'ach' ? 'tarjeta' : 'ach' });
    console.log('submitPayment', paymentIntent);
    return;
  }

  async paymentIntentSelectedMethod() {
    this.setLoading(true);
    const { paymentIntent, error } = await this.stripeService!.createPaymentIntent(
      {
        amount: this.total,
        idConcurso: this.showId,
        customer: this.defaultCustomer,
        paymentMethod: this.paymentMethod,
        metadata: this.metadata,
        description: this.description
      }).then(r => r).catch(this.handleError);;
    if (error) {
      this.setLoading(false);
      this.onError.emit(error);
      this.showMessage(error);
      return;
    }
    this.setLoading(false);
    this.onConfirmPayment.emit({ ...paymentIntent!, cardFee: this.paymentMethod == 'ach' ? 0 : this.cardFee, method: this.paymentMethod != 'ach' ? 'tarjeta' : 'ach' });
    console.log('submitPayment', paymentIntent);
    return;

  }

  // ------- UI helpers -------
  public showMessage(messageText: string) {
    const messageContainer: HTMLElement = ['ach', 'new_card'].includes(this.paymentMethod) ? document.querySelector("#payment-message")! : document.querySelector("#payment-message-pm")!;

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 10000);
  }

  // Show a spinner on payment submission
  public setLoading(isLoading: boolean = false) {
    this.processingPayment = isLoading;
  }

  private handleError(reason: any): any{
    const message = (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.'))));
    return { error: message };
  }
}
