<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-12 col-xl-8 offset-xl-2">
            <div class="card d-block">
              <div class="card-body">
                <button type="button" class="btn btn-dark" (click)="allEntries()">
                  <i class="mdi mdi-arrow-left-thick me-1"></i>
                  <span> All Entries</span>
                </button>

                <button class="btn btn-success float-right" id="btn-new-event" (click)="addClasses()">
                  <i class="mdi mdi-plus-circle-outline me-1"></i>
                  <span> Add Classes</span>
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-xl-8 offset-xl-2">
            <div class="card d-block enteredClasses">
              <div class="card-body">

                <h4 class="header-title mb-3">Currently Entered Classes</h4>
                <p><b>{{enteredClasses.length}}</b> Classes This Week</p>

                <div class="table-responsive">
                  <table class="table table-centered table-hover mb-0">
                    <tbody>
                      <tr *ngFor="let enteredClass of enteredClasses">
                        <td>
                          <h5 class="font-14 my-1">
                            <a href="javascript:void(0);" class="text-body">
                              <span class="text-muted font-12">{{enteredClass.number}}</span>
                              {{enteredClass.name}}
                            </a>
                          </h5>
                          <span class="text-muted font-13">Division: <b>{{enteredClass.division}}</b></span><br>
                          <span class="text-muted font-13">Date: {{enteredClass.date | date:'EEEE, MMMM d' }}</span>
                          <br>
                          <span class="badge"
                            [ngClass]="{'badge-success-lighten': enteredClass.status == 'can-drop', 'badge-warning-lighten': enteredClass.status != 'can-drop'}">{{enteredClass.status}}</span><br>
                        </td>
                        <td class="table-action">
                          <a *ngIf="enteredClass.status == 'can-drop' && idConcurso != '495'" href="javascript: void(0);" class="action-icon"
                            (click)="dropClass(enteredClass.id)"> <i class="mdi mdi-delete"></i>
                            <br>
                            <small>Drop</small>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> <!-- end table-responsive-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
