import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Firestore, writeBatch, doc } from '@angular/fire/firestore';
import { EntriesService } from "../services/entries.service";
import { Location } from '@angular/common'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  public idBinomio: string = '';
  public enteredClasses: any = [];
  public idConcurso: string;
  email: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entriesService: EntriesService,
    private location: Location,
    private _authService: AuthService,
    private _firestore: Firestore
  ) {
    const routeParams = this.route.snapshot.paramMap;
    this.idBinomio = routeParams.get('idBinomio')||'';
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.idConcurso = sessionStorage.getItem('showID') || '';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    this.getEnteredClasses();
  }

  public getEnteredClasses() {
    this.entriesService.getEnteredClasses(this.idBinomio).subscribe((response: any) => {
      this.enteredClasses = response.pruebas;
    }
    )
  }

  async dropClass(idPrueba: string) {
    this.entriesService.addDropClass(this.idBinomio, this.email, idPrueba).subscribe(
      async (response: any) => {
        //Eliminar binomio de firestore
        const batch = writeBatch(this._firestore);
        batch.delete(doc(this._firestore, 'resultados', `us_${idPrueba}`, 'resultados', `us_${this.idBinomio}`));
        batch.delete(doc(this._firestore, 'results', `us_${idPrueba}_${this.idBinomio}`));
        await batch.commit();
        this.entriesService.updateEntries(this.idConcurso, idPrueba);
        this.getEnteredClasses();
        this.router.navigate([this.router.url]);
      }
    );
  }

  addClasses() {
    this.router.navigate(['/add-classes/', this.idBinomio]);
  }

  allEntries() {
    this.router.navigate(['/add-drops/']);

  }
}
