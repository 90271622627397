import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from './../../environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { Firestore, setDoc, doc, Timestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FeedOrdersService {

  constructor(private http: HttpClient, private firestore: Firestore) {

  }

  public async getTrainer(email: string, idConcurso: string | number): Promise<any>{
    const params = JSON.stringify({ email, idConcurso });
    const headers = {
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    };

    return firstValueFrom(this.http.post(`${environment.api.ed.host}entrenadores/byEmail/`, params, { headers })).then((r: any) => ({ entrenador: r.entrenador||(r.entrenadores ? r.entrenadores[0] : null), error: r.message||(r.error||null) })).catch(reason => ({ error: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async getFeedOrders(idEntrenador: string | number, idConcurso: string | number): Promise<any>{
    const params = JSON.stringify({ idEntrenador, idConcurso });
    const headers = {
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    };

    return firstValueFrom(this.http.post(`${environment.api.ed.host}entrenadores/feed-orders/`, params, { headers })).then((r: any) => ({ orders: r.orders||null, error: r.message||(r.error||null) })).catch(reason => ({ error: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async getFeedConcepts(idConcurso: string | number): Promise<any>{
    const params = { c: idConcurso };
    const headers = {
      'x-api-key': environment.api.ed.key
    };

    return firstValueFrom(this.http.get(`${environment.api.ed.host}conceptos/feed-online/`, { headers, params })).then((r: any) => ({ conceptos: r.conceptos||null, error:r.message||(r.error||null) })).catch(reason => ({ error: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async createFeedOrder(order: any): Promise<any>{
    const params = JSON.stringify(order);
    const headers = {
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    };

    return firstValueFrom(this.http.post(`${environment.api.ed.host}orders/save/`, params, { headers })).then((r: any) => ({ order: r.order||null, error:r.message||(r.error||null) })).catch(reason => ({ error: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async editFeedOrder(order: any): Promise<any>{
    const params = JSON.stringify(order);
    const headers = {
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    };

    return firstValueFrom(this.http.post(`${environment.api.ed.host}orders/edit/`, params, { headers })).then((r: any) => ({ order: r.order||null, error:r.message||(r.error||null) })).catch(reason => ({ error: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public async createFeedOrderFS(order: any): Promise<any>{
    setDoc(doc(this.firestore, 'srjt', 'subCollections', 'Orders',`${order.id}`), {  ...order, created_at: Timestamp.fromDate(order.created_at)}, { merge: true});
  }
}

