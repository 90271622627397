import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { errorNotification, successNotification } from './../services/notifications.service';
import { Router } from '@angular/router';
import { PersonsService } from '../services/persons.service';
import { EmailsService } from '../services/emails.service';
import { environment } from './../../environments/environment';

declare var $: any;
declare let window: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public actions: any
  private personDocument: any;
  public version: string;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _personsService: PersonsService,
    private _emailsService: EmailsService

  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    })
    this.actions = {
      resetPassword: false
    }
    this.version = environment.version;
  }

  ngOnInit(): void {
    this._authService.logoutRequest();
    sessionStorage.clear();
    localStorage.clear();
  }

  public validateForm() {
    if (!this.loginForm.valid) {
      // #TODO: Add notification of invalid form
      $('.needs-validation').addClass('was-validated');
      return;
    }
    this.validateCredentials()
  }

  public openCloseResetPasswordForm() {
    this.actions.resetPassword = !this.actions.resetPassword;
  }

  public async resetPassword() {
    await this._authService.resetPassword(this.loginForm.value.username).then((response) => {
      if (response.error) {
        errorNotification('Error', 'Something went wrong, please check your email address and try again');
      } else {
        this.actions.resetPassword = false;
        successNotification('Success', 'A password reset request has been sent to your email. Please check your inbox.');
      }
    }).catch(error => {
      console.log(error)
    });
  }

  public async validateCredentials() {
    console.log('0 redirect to select show');
    const { userCredential, reason } = await this._authService.loginRequest(this.loginForm.value.username, this.loginForm.value.password).then((userCredential) => ({ userCredential, reason: null })).catch(reason => ({ reason, userCredential: null }));
    if (reason) {
      errorNotification('Error', reason);
      if(reason.code != 'auth/user-not-found' && reason.code != 'auth/wrong-password'){
      this._emailsService.sendEmail(
        'Error logging in',
        'User: ' + this.loginForm.value.username + ' tried to login but failed. \n Error: ' + reason,
      )}
      return;
    }
    const uid = userCredential.user.uid;
    const email = userCredential.user.email;
    const displayName = userCredential.user.displayName||'';
    this.personDocument = await this._personsService.getDocumentByEmail(email, uid).catch((error: any) => { errorNotification(error.error.code, error.error.message) });

    this._authService.identifyUserLOG(this.personDocument.uid, email, displayName);

    localStorage.setItem('user_document_id', this.personDocument.uid);
    localStorage.setItem('email', email);
    localStorage.setItem('isAdmin', environment.developers.includes(email) ? '1' : '0');
    localStorage.setItem('username', displayName);

    window.__lo_site_id = 297466;
    const userData = {
      'email': email,
      'name': email
    };

    window._loq = window._loq || [];
    window._loq.push(['custom', userData]);
    const { reason2 } = await this._authService.createUserDoc(this.personDocument.uid, email).then(() => ({ reason2: null })).catch(reason2 => ({ reason2 }));
    if (reason2) {
      console.log('2 redirect to select show');
      errorNotification(reason2.error.code, reason2.error.message);
      this._emailsService.sendEmail(
        'Error creating userDoc after logging in',
        'User: ' + this.loginForm.value.username + ' tried to create the userDoc after logging in but failed. \n Error: ' + reason2,

      );
      return;
    }

    //redirect to select show
    console.log('3 redirect to select show');
    this._router.navigate(['/select-show']);
  }
}
