import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { EntriesService } from './../services/entries.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PersonsService } from './../services/persons.service';
import { AuthService } from './../services/auth.service';
import { firstValueFrom } from 'rxjs'
declare let $: any;

@Component({
  selector: 'app-trainer-reservation',
  templateUrl: './trainer-reservation.component.html',
  styleUrls: ['./trainer-reservation.component.scss'],
  animations: [
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms', style({ opacity: 1 }))
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('500ms', style({ opacity: 0 }))
			])
		])
	]
})
export class TrainerReservationComponent implements OnInit {
  public conceptos: any[];
  public loading: boolean;
  public idConcurso: string;
  public conceptosSeleccionados: any[];
  public email: string;
  public idEntrenador: string;
  public cards: any[];
  public form: FormGroup;
  public reservations: any[];
  public folio: string;
  public nombreConcurso: string;
  public public_key: string;

  constructor(private entriesService: EntriesService, private personsService: PersonsService, private _authService: AuthService) {
    this.conceptos = [];
    this.loading = true;
    this.idConcurso = sessionStorage.getItem('showID')||'';
    this.conceptosSeleccionados = [];
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.idEntrenador = localStorage.getItem('idEntrenador')||'';
    this.cards = JSON.parse(localStorage.getItem('cards')||'[]');
    this.form = new FormGroup({
      idPersona: new FormControl(this.idEntrenador, [Validators.required]),
      idConcurso: new FormControl(this.idConcurso, [Validators.required]),
      ccAccountNum: new FormControl('', [Validators.required, Validators.minLength(18)/*, Validators.maxLength(19)*/]),
      ccExp: new FormControl('', [Validators.required, Validators.minLength(7)]),
      customerName: new FormControl('', [Validators.required]),
      customerAddress1: new FormControl('', []),
      customerAddress2: new FormControl('', []),
      customerCity: new FormControl('', []),
      customerState: new FormControl('', []),
      customerZIP: new FormControl('', []),
      customerPhone: new FormControl('', []),
      customerCountryCode: new FormControl('', [])
    });
    this.reservations = [];
    this.folio = '';
    this.nombreConcurso = sessionStorage.getItem('nombreConcurso')||'';
    this.public_key = '';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.idConcurso) return;
    if(!this.idEntrenador) await this.getTrainerByEmail();
    this.getConcurso();
    this.getReservations();
    this.getConceptos();
  }

  private getConcurso(){
    this.entriesService.getConcurso(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.public_key = response.concurso.public_key;
        } else{
          $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send('Error', "An error occurred when show info was consulted, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
				console.log(error);
      }
    );
  }

  private getReservations(){
    if(!this.idEntrenador) return;
    this.entriesService.reservacionesPorEntrenador(this.idEntrenador, this.idConcurso).subscribe(
      response => {
        if(!response['error']){
          this.reservations = response['reservations'];
        } else{
          this.loading = false;
          $.NotificationApp.send('Error', response['message'], 'bottom-right', '#fa5c7c', 'error', 5000);
          console.log(response['message']);
        }
      },
      error => {
        $.NotificationApp.send('Error', "An error occurred when the reservations was consulted, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
				console.log(error);
      }
    );
  }

  public getConceptos(){
		this.entriesService.getAllConcepts(this.idConcurso).subscribe(
			(response: any) => {
				if(!response.error){
          this.conceptos = response.conceptos.filter((c: any) => c.reservation).map((c: any) => ({ id: c.id, nombre: c.nombre, monto: parseFloat(c.monto||'0') }));
          this.loading = false;
				} else{
					this.loading = false;
					$.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
					console.log(response.message);
				}
			},
			error => {
				this.loading = false;
				$.NotificationApp.send('Error', "An error occurred when the classes information was consulted, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
				console.log(error);
			}
		);
	}

  public agergarCargo(idConcepto: string, monto: number, nombre: string, e: any){
    const qty = isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber;
    if(qty < 0){
      e.target.value = '';
      return;
    }
		const index = this.conceptosSeleccionados.findIndex(concepto => {
			return concepto.idConcepto == idConcepto;
		});
		if(qty == 0){ //Si es 0 borrar de los conceptos seleccionados
			if(index > -1){ //Ya existe en el array conceptos seleccionados
				this.conceptosSeleccionados.splice(index, 1);
			} else{
				//No existe entonces no hay que borrar nada
			}
		} else{
			if(index > -1){ //Ya existe en el array conceptos seleccionados hay que editarlo
				this.conceptosSeleccionados[index].qty = qty;
				this.conceptosSeleccionados[index].total = monto * qty;
			} else{ //Aun no existe en el array hay que agregarlo
				this.conceptosSeleccionados.push({
					idConcepto,
					monto,
					nombre,
					qty,
					total: monto * qty
				});
			}
		}
	}

  public totalCargos(): number{
    return this.conceptosSeleccionados.reduce((a, b) => {
     return a + b.total;
    }, 0);
  }

  public save(): void{
    if(this.conceptosSeleccionados.length > 0){
      this.loading = true;
      this.entriesService.solicitudTrainerReservation(this.idEntrenador, this.idConcurso, this.conceptosSeleccionados, this.email, this.nombreConcurso).subscribe(
        (response: any) => {
          if(!response.error){
            this.getReservations();
            this.conceptosSeleccionados = [];
            $('input[type=number]').val('');
            //$.NotificationApp.send('Success', "Your reservation has been created successfully.", 'bottom-right', '#3c763d', 'success', 5000);
            this.folio = response.id;
            $('#modal-folio').modal('show');

            this.loading = false;
          } else{
            this.loading = false;
            $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
            console.log(response.message);
          }
        },
        error => {
          this.loading = false;
          $.NotificationApp.send('Error', "An error occurred while submitting the reservation, please try again.", 'bottom-right', '#fa5c7c', 'error', 5000);
          console.log(error);
        }
      );
    }
  }

  public addCard(): void{
    this.loading = true;
    if(this.form.valid){
      this.entriesService.addCard(this.form.value).subscribe(
        response => {
          if(!response['error']){
            this.cards = response['cards'];
            localStorage.setItem('cards', JSON.stringify(response['cards']));
            this.loading = false;
          } else{
            this.loading = false;
            $.NotificationApp.send("Error", response['message'], 'bottom-right', '#fa5c7c', 'error');
            console.log(response['message']);
          }
        },
        error => {
          this.loading = false;
          $.NotificationApp.send("Error", "It has not been possible to save the card.", 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }

  public async getTrainerByEmail(){
    const response = await this.personsService.getByEmail(this.email, this.idConcurso);
    if(response.error){
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    }
    const entrenador = response.entrenador;
    this.idEntrenador = entrenador.idEntrenador;
    try{
      this.cards = JSON.parse(entrenador.cards||'[]');
    } catch(e){
      this.cards = [];
    }
  }
}
